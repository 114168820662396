.Budget {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


    background-color: #161E38;
    color: white; /* Set text color to white or a contrasting color */
  }
  

  .pdf-container {
    display: flex;
    justify-content: center;
  }