.app__footerOverlay {
    width: 100%;
    height: 100%;
    z-index: -1;

    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0; /*use*/
    bottom: 0; /*use*/
}

.app__footerOverlay-black {
    height: 25%;
    background: var(--color-black);
    /*background-color: #161E38;*/
}

.app__footerOverlay-img {
    height: 75%;
}
