.photo-flipper-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
  }
  @media screen and (min-width: 768px) {
    .photo-flipper-container {
      margin: 40px;
    }
  }
  @media screen and (min-width: 1024px) {
    .photo-flipper-container {
      margin: 60px;
    }
  }
  
  
  .photo-flipper-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px; /* 20px; this is space between div and surrounding whitespace*/
    background-color: #161E38;
    border : none;
    /*position: relative;  add position relative for child absolute positioning */
    /*overflow: hidden; /* hide any overflow */
  }