.photo-flipper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    border: 0px solid var(--color-golden); /*1px gives a nice outline*/
    border-radius: 4px;
  }
  .photo-flipper button {
    font-size: 1.2rem;
    background-color: var(--color-golden);
    color: #161e38;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  .photo-flipper button:hover {
    background-color: #aaa;
  }
  .photo-flipper img {
    max-width: 100%;
    height: auto;
    margin: 0 20px;
  }
  @media screen and (max-width: 768px) {
    .photo-flipper {
      flex-direction: column;
      align-items: center;
    }
    .photo-flipper img {
      margin: 20px 0;
    }
  }
  